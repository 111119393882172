var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('translation-locales'), _c('v-card', [_c('v-card-text', [_c('v-select', {
    attrs: {
      "label": "Scheme (associated from Core)",
      "item-text": "title",
      "item-value": "value",
      "outlined": "",
      "items": _vm.schemeOptions,
      "rules": [_vm.required(_vm.form.gateway_id)]
    },
    on: {
      "input": _vm.schemeChanged
    },
    model: {
      value: _vm.form.gateway_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "gateway_id", $$v);
      },
      expression: "form.gateway_id"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "label": "Assigned Distributor",
      "item-text": "title",
      "item-value": "value",
      "outlined": "",
      "rules": [_vm.between(_vm.form.distributor_id, 1, 99999999)],
      "items": _vm.distributorOptions
    },
    model: {
      value: _vm.form.distributor_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "distributor_id", $$v);
      },
      expression: "form.distributor_id"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Reference Name",
      "outlined": "",
      "disabled": ""
    },
    model: {
      value: _vm.form.nicename,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nicename", $$v);
      },
      expression: "form.nicename"
    }
  })], 1), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  }), _c('div', [_c('ul', [_vm._v(" Discount Scheme is an extra discount offered to all users under a distributor. "), _c('li', [_vm._v("Only the first scheme from each distributor will be applied.")])])])], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
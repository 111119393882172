<template>
  <div>
    <v-row class="my-0 d-flex justify-end">
      <v-col cols="12" sm="2" style="margin-right:auto" align="right">
        <v-btn color="primary" block class="" :loading="loading" :disabled="loading"
          @click="loadDiscountSchemes">
          <v-icon class="prefix-icon">{{ icons.mdiRefresh }}</v-icon>
          <span>
            {{$t('common.list.refresh')}}
          </span>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="2" align="right">
        <v-btn depressed block color="success" @click="setAside(null,'scheme')">
          <v-icon class="prefix-icon">{{ icons.mdiPlus }}</v-icon>
          {{$t('common.list.create')}}
        </v-btn>
      </v-col>
    </v-row>

    <v-card>
      <!-- table -->
      <v-data-table v-model="tableSelectedData" :headers="tableColumns" :items="tableList"
        :options.sync="options" :server-items-length="tableTotal" :loading="loading"
        class="text-no-wrap" :headerProps="headerprops">
        <!-- ID -->
        <template #[`item.id`]="{ item }">
          #{{ item.id }}
        </template>
        <!-- title -->
        <template #[`item.title`]="{item}">
          {{ t(item.title) }}
          <v-chip v-if="item.trial" color="success" small>
            TRIAL
          </v-chip>
          <v-chip v-if="item.bulk" color="info" small>
            BULK
          </v-chip>
        </template>

        <!-- distributor -->
        <template #[`item.distributor_id`]="{item}">
          {{ item.distributor.nicename }}
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small icon v-bind="attrs" v-on="on">
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item link @click="setAside(item, 'scheme')">
                  <v-list-item-title>
                    <v-icon size="18" class="me-2">
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Edit</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item link @click="setAside(item, 'card_layout')">
                  <v-list-item-title>
                    <v-icon size="20" class="me-2">
                    </v-icon>
                    <span>Update Layout</span>
                  </v-list-item-title>
                </v-list-item>

                <!-- <v-list-item
                    link
                    @click="deleteDiscountScheme(item)"
                  >
                    <v-list-item-title>
                      <v-icon
                        size="18"
                        class="me-2"
                      >
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                      <span>Delete</span>
                    </v-list-item-title>
                  </v-list-item> -->
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <discount-scheme-aside v-if="aside === 'scheme'" v-model="asideActive" :resource="resource"
      @aside="val => { if (!val) { aside = '' } }" @changed="changedDiscountScheme">
    </discount-scheme-aside>
  </div>
</template>

<script>
import { mdiDeleteOutline, mdiDotsVertical, mdiPencilOutline, mdiMenuUp, mdiMenuDown, mdiRefresh, mdiPlus } from '@mdi/js'

import { t } from '@/plugins/i18n'
import { ref } from '@vue/composition-api'

import DiscountSchemeAside from '../discount-scheme-resource/DiscountSchemeAside.vue'
import useDiscountSchemeList from './useDiscountSchemeList'

export default {
  components: {
    DiscountSchemeAside,
  },

  setup() {
    const { tableList, tableColumns, tableTotal, tableSelectedData, searchQuery, emailQuery, options, loadDiscountSchemes, deleteDiscountScheme, loading } =
      useDiscountSchemeList()

    const asideActive = ref(false)
    const resource = ref(null)
    const aside = ref(null)
    const setAside = (item, type) => {
      asideActive.value = true
      aside.value = type
      resource.value = item
    }

    const getLayout = item => item.layouts[item.layouts.length - 1]

    const changedDiscountScheme = scheme => {
      loadDiscountSchemes()
      aside.value = ''
      asideActive.value = false
    }

    const singleSelect = ref(false)
    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })
    const showFilter = ref(true)

    return {
      tableColumns,
      tableList,
      tableSelectedData,
      tableTotal,

      searchQuery,
      emailQuery,

      loadDiscountSchemes,
      deleteDiscountScheme,

      setAside,
      asideActive,
      aside,
      resource,

      options,
      loading,

      t,
      getLayout,
      changedDiscountScheme,

      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
      },
      singleSelect,
      headerprops,
      showFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>

<template>
  <v-form ref="formElem">
    <translation-locales />

    <v-card>
      <v-card-text>
        <v-select
          v-model="form.gateway_id"
          label="Scheme (associated from Core)"
          item-text="title"
          item-value="value"
          outlined
          :items="schemeOptions"
          :rules="[required(form.gateway_id)]"
          @input="schemeChanged"
        ></v-select>

        <v-autocomplete
          v-model="form.distributor_id"
          label="Assigned Distributor"
          item-text="title"
          item-value="value"
          outlined
          :rules="[between(form.distributor_id, 1, 99999999)]"
          :items="distributorOptions"
        ></v-autocomplete>

        <v-text-field
          v-model="form.nicename"
          label="Reference Name"
          outlined
          class="mt-5"
          disabled
        />
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>

      <div>
        <ul>
          Discount Scheme is an extra discount offered to all users under a distributor.
          <li>Only the first scheme from each distributor will be applied.</li>
        </ul>
      </div>
    </v-card>
  </v-form>
</template>

<script>
import { TranslationLocales } from '@/components'
import { useDistributor } from '@/composables'
import productStore from '@/modules/product/store'
import { t } from '@/plugins/i18n'
import { between, required } from '@core/utils/validation'
import { ref } from '@vue/composition-api'

export default {
  components: { TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const field = (name, initValue) => ({ [name]: props.resource ? props.resource[name] : initValue })

    const initialForm = {
      ...field('nicename', null),
      ...field('distributor_id', null),
      ...field('gateway_id', null),
      ...field('gateway_type', 'core'),
    }

    const form = ref({ ...initialForm })
    const formElem = ref(null)

    const validate = () => {
      if (!formElem.value.validate()) return
      const data = { ...form.value }
      emit('submit', data)
    }

    const { distributorOptions } = useDistributor()

    const { coreSchemes } = productStore.state

    const schemeOptions = coreSchemes.map(scheme => ({
      title: `${t(scheme.name)}`,
      value: scheme.uuid,
    }))

    const schemeChanged = () => {
      const scheme = coreSchemes.find(s => s.uuid === form.value.gateway_id)
      if (scheme) {
        form.value.nicename = t(scheme.name)
      }
    }

    schemeChanged()

    return {
      form,
      formElem,
      validate,
      required,
      between,

      distributorOptions,
      schemeOptions,
      schemeChanged,
    }
  },
}
</script>
